<!-- =========================================================================================
  File Name: ItemGridView.vue
  Description: Item Component - Grid VIew
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <!-- <div class="item-grid-view vx-row match-height">
      <div class="vx-col" :class="gridColumnWidth" v-for="item in items" :key="item.objectID"> -->
  <vx-card class="grid-view-item mb-base overflow-hidden" v-on="$listeners">
    <template slot="no-body">

      <!-- ITEM IMAGE -->
      <div @click="navigate_to_detail_view"
           class="item-img-container bg-white flex items-center justify-center mb-4 cursor-pointer">
        <img :alt="item.name"
             :src="`https://portal-cdn.rapid.education/product-pictures/${item.image||'default'}.jpeg`"
             class="grid-view-img" style="padding:0!important;width:100%">
      </div>
      <div class="item-details px-4">

        <!-- RATING & PRICE -->
        <div class="flex justify-between items-center">
          <div class="flex">
            <h3 @click="navigate_to_detail_view" class=" font-semibold mb-1 hover:text-primary cursor-pointer">
              {{ item.name }}
            </h3>
          </div>
        </div>

        <!-- TITLE & DESCRIPTION -->
        <div class="my-4 mb-8">
          <nl2br tag="p" :text="item.description" class-name="item-description text-md" />
        </div>
      </div>
      <!-- <slot name="action-buttons"/>-->
    </template>
  </vx-card>
  <!--  </div>
</div> -->
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    navigate_to_detail_view() {
      this.$router.push(`/shop/${this.item.id}`).catch(() => {
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuexy/_variables.scss";

.grid-view-item {
  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: .35s;
  }

  .item-img-container {
    background: $theme-dark-bg !important;
  }

  .simulation-card-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1.25rem;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, .25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
